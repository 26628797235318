import React from 'react';
import { TextInputRegistration } from '../../../components/TextInput/TextInput';
import { citizenshipTextValidation, citizenshipValidation } from '../../../shared/functions/validations';

const CitizenshipField = ({ state, dispatch }) => {
  return (
    <>
      <h1 className='test__question-title'>Гражданство*</h1>
      <div className='radio-group-wrapper'>
        {['1. Российская Федерация', '2. Другое'].map((answer, index) => (
          <label key={`radio-group-${index}`} htmlFor={`radio-group-${index}`} className='radio-group__label'>
            <input
              type='radio'
              id={`radio-group-${index}`}
              name='radio question'
              value={state.is_russian_citizenship}
              checked={['1. Российская Федерация', '2. Другое'][index] === state.is_russian_citizenship}
              onChange={e => {
                dispatch({ type: 'SET_RUSSIAN_CITIZENSHIP', payload: answer });
              }}
              className={`radio-group__radio ${['1. Российская Федерация', '2. Другое'][index] === state.is_russian_citizenship ? 'checked' : ''}`}
            />
            <div className='radio-group__radio-indicator' />
            {answer}
          </label>
        ))}
        {state.is_russian_citizenship === '2. Другое' &&
          <TextInputRegistration
            value={state.citizenship}
            dispatch={dispatch}
            dispatchType='SET_CITIZENSHIP'
            placeholder='Гражданство'
            validation={state.validation}
            error={!citizenshipTextValidation(state.citizenship)}
            errorText={'Пожалуйста, введите гражданство'}
            autocomplete='off'
          />
        }
        {state.validation && !citizenshipValidation(state.is_russian_citizenship) && (
          <span style={{ color: '#ef3124', fontSize: '12px' }}>Пожалуйста, выберите гражданство</span>
        )}
      </div>
    </>
  );
};

export default CitizenshipField;
