import React from 'react';
import { TextInputRegistration } from '../../../components/TextInput/TextInput';
import { isEmptyString, middleNameValidation, testName } from '../../../shared/functions/validations';

const NameField = ({ state, dispatch }) => {
  const errorLastNameEmpty = isEmptyString(state.last_name) && 'Пожалуйста, введите фамилию'
  const errorFirstNameEmpty = isEmptyString(state.first_name) && 'Пожалуйста, введите имя'

  const errorLastNameError = !testName(state.last_name) && 'Пожалуйста, используйте русский язык'
  const errorFirstNameError = !testName(state.first_name) && 'Пожалуйста, используйте русский язык'


  return (
    <>
      <h1 className='test__question-title'>Фамилия*</h1>
      <TextInputRegistration
        value={state.last_name}
        dispatch={dispatch}
        dispatchType='SET_LAST_NAME'
        placeholder='Иванов'
        validation={state.validation}
        error={isEmptyString(state.last_name) || !testName(state.last_name)}
        errorText={errorLastNameEmpty || errorLastNameError}
        autocomplete='off'
      />

      <h1 className='test__question-title'>Имя*</h1>
      <TextInputRegistration
        value={state.first_name}
        dispatch={dispatch}
        dispatchType='SET_FIRST_NAME'
        placeholder='Иван'
        validation={state.validation}
        error={isEmptyString(state.first_name) || !testName(state.first_name)}
        errorText={errorFirstNameEmpty || errorFirstNameError}
        autocomplete='off'
      />

      <h1 className='test__question-title'>Отчество</h1>
      <TextInputRegistration
        value={state.middle_name}
        dispatch={dispatch}
        dispatchType='SET_MIDDLE_NAME'
        placeholder='Иванович'
        validation={state.validation}
        error={!middleNameValidation(state.middle_name)}
        errorText={'Пожалуйста, используйте русский язык'}
        autocomplete='off'
      />
    </>
  );
};

export default NameField;
