import React from 'react';
import AutocompleteInput from '../../../components/AutocompleteInput';
import { testLivingCity } from '../../../shared/functions/validations';

const LivingCityField = ({ state, dispatch }) => {
  return (
    <>
      <h1 className='test__question-title'>Город проживания*</h1>
      <AutocompleteInput
        disable={!state.living_city_list}
        items={state.living_city_list?.map((i, j) => ({ id: j, text: i })) ?? []}
        value={state.living_city}
        dispatch={dispatch}
        dispatchType='SET_LIVING_CITY'
        onlySelect={false}
      />
      <p style={{ color: '#888' }} />

      <span style={{ fontSize: '12px' }}>Пожалуйста, укажите город в котором вы проживаете.</span>
      <br />

      {state.validation && !testLivingCity(state.living_city) && (
        <>
          <span style={{ color: '#ef3124', fontSize: '12px' }}>Пожалуйста, выберите из списка или введите свой город</span>
        </>
      )}
    </>
  );
};

export default LivingCityField;
