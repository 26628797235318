import React from 'react';
import { isDobOlder, isDobRange, textErrorDobOlder, textErrorDobRange } from '../../../shared/constants/settingsProject';
import { dobValidation, testDob } from '../../../shared/functions/validations';

const DateOfBirthField = ({ state, dispatch }) => {
  return (
    <>
      <h1 className='test__question-title'>Дата рождения*</h1>
      <div className='text-input'>
        <input
          type='text'
          value={state.dob}
          maxLength={10}
          placeholder='дд.мм.гггг'
          onChange={e => {
            if (e.target.value.length < state.dob.length && e.target.value.length === 0) {
              return dispatch({ type: 'SET_DOB', payload: '' });
            }

            if (!/\d/.test(e.target.value.slice(-1)) && !/\./.test(e.target.value.slice(-1))) {
              return;
            }

            if (e.target.value.length === 2 || e.target.value.length === 5) {
              if (e.target.value.length < state.dob.length) {
                return dispatch({ type: 'SET_DOB', payload: e.target.value.slice(0, -1) });
              }

              dispatch({ type: 'SET_DOB', payload: `${e.target.value}.` });
            } else {
              dispatch({ type: 'SET_DOB', payload: e.target.value });
            }
          }}
        />
        {testDob(state.dob) && !dobValidation(state.dob) && (
          <>
            
            <span>
              {isDobRange && textErrorDobRange}
              {isDobOlder && textErrorDobOlder}
            </span>
            <br />
          </>
        )}
        {state.validation && !testDob(state.dob) && (
          <span>Пожалуйста, введите дату рождения в формате дд.мм.гггг</span>
        )}
      </div>
    </>
  );
};

export default DateOfBirthField;
