import React from 'react';
import Popup from 'reactjs-popup';

const PDAField = ({ state, dispatch }) => {
  return (
    <>
      <div className='pd-agreement' style={{ marginBottom: '10px' }}>
        <input
          type='checkbox'
          value='yes'
          className='pd-agreement__input'
          onChange={() => dispatch({ type: 'SET_PD_AGREEMENT', payload: !state.pd_agreement })}
        />
        <input
          type='checkbox'
          value='yes'
          className='pd-agreement__input'
          onChange={() => dispatch({ type: 'SET_PD_AGREEMENT', payload: !state.pd_agreement })}
        />
        <div className={`pd-agreement__checkbox ${state.pd_agreement ? 'checked' : ''}`} />
        <label className='pd-agreement__label' onClick={() => dispatch({ type: 'SET_PD_AGREEMENT', payload: !state.pd_agreement })}>
            {/* eslint-disable-next-line */}
            Согласен на обработку моих персональных данных в соответствии с Федеральным законом "О персональных данных" от 27.07.2006 N 152-ФЗ
            {/* Я согласен c <Popup trigger={<span>условиями обработки и использования</span>} modal position="center">{close => (<div className="modal"> <a className="close" onClick={close}>&times;</a> <div className='pd-info'>Я даю свое согласие АО «АЛЬФА-БАНК» на обработку моих персональных данных, указанных в настоящей заявке, включая любые действия, предусмотренные Федеральным законом от 27.07.2006 года № 152-ФЗ «О персональных данных» с использованием средств автоматизации или без использования таких средств, в целях обработки моей заявки и участия в отборочном туре Онлайн-чемпионата. Настоящее согласие на обработку персональных данных действует до достижения целей обработки и может быть отозвано субъектом персональных данных в любое время при представлении Банку заявления по форме, установленной Банком для отзыва Согласия. </div></div>)}</Popup> моих персональных данных* */}
        </label>
      </div>
      {state.validation && !state.pd_agreement && (
        <span style={{ color: '#ef3124', fontSize: '12px', paddingTop: '10px' }}>Для регистрации необходимо дать согласие на обработку персональных данных</span>
      )}
    </>
  );
};

export default PDAField;
