export let URL = '';

if (process.env.NODE_ENV === 'production') {
  URL = 'https://apitest.alfafactory.site';
} else {

  URL = 'https://apitest.alfafactory.site';
}

export const GET_USE_FORMS = `${URL}/api/userforms/`;
export const POST_USER_FORMS = `${URL}/api/userforms/`;

export const GET_START_PAGE = `${URL}/api/startpage/`;

export const PHONE_UPDATE = uuid => `${URL}/api/${uuid}/phone_update/`;

export const GET_QUESTION = uid => `${URL}/api/${uid}/questions/`;
export const POST_ANSWER = uid => `${URL}/api/${uid}/answers/`;
export const PUT_ANSWER = (uid, id) => `${URL}/api/${uid}/answers/${id}`;

export const PUT_CONFIRM = uid => `${URL}/api/confirmation/${uid}`;

export const GET_CAPTCHA = `${URL}/api/captcha/get/`;
export const POST_CAPTCHA = `${URL}/api/captcha/check/`;
export const POST_CAPTCHA_REFRESH = `${URL}/api/captcha/refresh/`;
