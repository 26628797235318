// Включение/выключение вхождения года рождения (противоречит isDobOlder)
export const isDobRange = false
// Отказ в регистрации если младше YYYY года
export const yongerYYYY = 2002
// Отказ в регистрации если старше YYYY года
export const olderYYYY = 1994

/* ---------- */

// Включение/выключение вхождения года рождения (противоречит isDobRange)
export const isDobOlder = true
// Отказ в регистрации если младше. https://calculator888.ru/skolko-dnei/ - тут высчтить сколько дней
export const olderDays = 6575 // 18 лет

/* ---------- */

// Текстовая ошибка, если не входит в isDobRange
export const textErrorDobRange = 'Участник должен быть не старше 1994г. и не моложе 2002г.'
// Текстовая ошибка, если не входит в isDobOlder
export const textErrorDobOlder = 'Возраст участников должен быть старше 18 лет'

/* ---------- */
