import React from 'react';
import { citizenshipValidation } from '../../../shared/functions/validations';

const MoscowRegion = ({ state, dispatch }) => {
  return (
    <>
      <h1 className='test__question-title'>Вы сейчас проживаете в Москве или в ближайшем Подмосковье?*</h1>
      <div className='radio-group-wrapper'>
        {state.moscow_region_choices.map((answer, index) => (
          <label key={`radio-group-moskow-${index}`} htmlFor={`radio-group-moskow-${index}`} className='radio-group__label'>
            <input
              type='radio'
              id={`radio-group-moskow-${index}`}
              name='radio question'
              value={state.moscow_region}
              checked={state.moscow_region_choices[index] === state.moscow_region}
              onChange={e => {
                dispatch({ type: 'SET_MOSKOW_REGION', payload: answer });
              }}
              className={`radio-group__radio ${state.moscow_region_choices[index] === state.moscow_region ? 'checked' : ''}`}
            />
            <div className='radio-group__radio-indicator' />
            {answer}
          </label>
        ))}
        {state.validation && !citizenshipValidation(state.moscow_region) && (
          <span style={{ color: '#ef3124', fontSize: '12px' }}>Пожалуйста, выберите ответ</span>
        )}
      </div>
    </>
  );
};

export default MoscowRegion;
