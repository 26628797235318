import React, { useReducer, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import KeyboardEventHandler from 'react-keyboard-event-handler';
import ym from 'react-yandex-metrika';
import ReactPixel from 'react-facebook-pixel';

import { reducer, initialState } from './reducer';

import Header from '../../components/Header';

import NameField from './fields/NameField';
import PhoneNumberField from './fields/PhoneNumberField';
import EmailField from './fields/EmailField';
import DateOfBirthField from './fields/DateOfBirthField';
import LivingCityField from './fields/LivingCityField';
import LivingRegionField from './fields/LivingRegionField';
import CitizenshipField from './fields/CitizenshipField';
import Captcha from '../../components/Captcha';
import PDAField from './fields/PDAField';

import requestRegistrationData from './functions/requestRegistrationData';

import Footer from '../../components/Footer';

import { GET_START_PAGE, POST_USER_FORMS, POST_CAPTCHA } from '../../settings/endpoint';

import './Registration.scss';
import { registrationValidation } from '../../shared/functions/registrationValidation';
import MoscowRegion from './fields/MoscowRegion';

const QUESTIONS_COUNT = 1;

// const birthDateToAge1 = birthDate => birthDate.slice(6) <= 2001;
// const birthDateToAge2 = birthDate => birthDate.slice(6) >= 1993;

const Registration = () => {
  const history = useHistory();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [step, setStep] = useState(1)

  useEffect(() => {
    // if (process.env.NODE_ENV === 'production') {
    //   ym('reachGoal', 'order1');
    // }

    // ReactPixel.track('Начало оформления заказа');
  }, []);

  // useEffect(() => {
  //   if (state.close_test_text) {
  //     ReactPixel.track('Завершенная регистрация');
  //   }
  // }, [state.close_test_text]);

  useEffect(() => {
    // eslint-disable-next-line no-restricted-globals
    window.scrollTo(pageXOffset, 0);
  }, [state.current_count, state.close_test_text]);

  useEffect(() => {
    axios
      .get(GET_START_PAGE)
      .then(res => {
        dispatch({ type: 'GET_LIVING_CITY_LIST', payload: res.data.living_city_list });
        dispatch({ type: 'SET_MOSKOW_REGION_CHOISES', payload: res.data.moscow_region_choices });

        return null;
      })
      .catch(err => new Error(err)); //
  }, []);

  const handlePrev = () => {
    if (state.current_count !== 1) {
      dispatch({ type: 'DECREMENT_COUNT' });
    }
  };


  // const [captcha, setCaptcha] = useState(null);
  // const [captchaValue, setCaptchaValue] = useState('');
  // const [isError, setError] = useState(false);
  // const [isLoading, setLoading] = useState(false);
  // const [errorCounter, setErrorCounter] = useState(0);

  const handleNext = () => {
    if (step === 1) {
      setStep(2)
      return
    }

    let company_name = '';

    if (/\/Alfabank\//.test(history.location.pathname)) {
      company_name = 'Alfabank';
    }

    if (/\/X5\//.test(history.location.pathname)) {
      company_name = 'X5';
    }

    if (/\/Beeline\//.test(history.location.pathname)) {
      company_name = 'Beeline';
    }

    if (/\/AlfaStrakhovanie\//.test(history.location.pathname)) {
      company_name = 'AlfaStrakhovanie';
    }

    if (registrationValidation({
      lastName: state.last_name,
      firstName: state.first_name,
      middleName: state.middle_name,
      email: state.email,
      dob: state.dob,
      phone: state.phone,
      livingCity: state.living_city,
      pdAgreement: state.pd_agreement,
      moscowRegion: state.moscow_region,
      moscowRegionChoices: state.moscow_region_choices,
      citizenship: state.is_russian_citizenship,
      citizenshipText: state.citizenship,
    })) {
      dispatch({ type: 'SET_PENDING', payload: true });

      // axios
      //   .post(POST_CAPTCHA, {
      //     captcha_uuid: captcha?.captcha_uuid,
      //     captcha_value: captchaValue,
      //   })
      //   .then(res => {
      //     if (res.data.is_valid) {
            axios
            .post(POST_USER_FORMS, {
              ...requestRegistrationData(state),
              company_name,
              redirect_from: history.location.search
            })
            .then(res => {
              // if (process.env.NODE_ENV === 'production') {
              //   ym('reachGoal', 'sendForm');
              //   window.VK.Goal('conversion');
              // }
              if (res.data.current_question ) {
                history.push(`/test/${res.data.userform_uuid}`);
                return null;
              }
              

              dispatch({
                type: 'REGISTRATION_CONFIRM',
                payload: {
                  link: res.data.userform_uuid === '' ? '' : `${window.location.origin}/test/${res.data.userform_uuid}`,
                  text: res.data.register_phrase,
                }
              });
    
              return null;
            })  
            .catch(err => {
              dispatch({ type: 'SET_PENDING', payload: false });
    
              return new Error(err);
            });
        //   } else {
        //     setErrorCounter(prev => prev + 1);
        //     setError(true);
        //     dispatch({ type: 'SET_PENDING', payload: false });
        //   }
  
        //   return;
        // })
        // .catch(err => {
        //   setError(true);
        //   dispatch({ type: 'SET_PENDING', payload: false });
        //   setErrorCounter(prev => prev + 1);
  
        //   return new Error(err);
        // });
    } else {
      dispatch({ type: 'VALIDATION_ON' })
    }
  };

  return (
    <div className='test-wrapper'>
      <KeyboardEventHandler
        handleKeys={['Enter']}
        onKeyEvent={() => {
          // if (!btnNextDisabled(state.current_count) && !state.isPending) {
          if (!state.is_pending) {
            handleNext();
          }
        }}
      />
      <div className='test'>
        <Header title={'Регистрация'} current_count={state.current_count} count={QUESTIONS_COUNT} />

        <div className='test__question-wrapper'>
          <div
            className='test__question'
            onKeyPress={e => {
              if (e.key === 'Enter') {
                // if (!btnNextDisabled(state.current_count) && !state.is_pending) {
                if (!state.is_pending) {
                  handleNext();
                }
              }
            }}
          >
            {step === 1 && (
              <h1 className='test__question-title' style={{ textAlign: 'left' }}>
                  Добрый день!
                  <br />
                  <br />
                  Вы приступили к заполнению анкеты для
                  участия в образовательном курсе Alfa Factory.
                  <br />
                  Для того чтобы начать регистрацию нажмите
                  кнопку &quot;Далее&quot;.
                  <br />
                  <br />
                  Следите за новостями на
                  <span> <a href='https://t.me/AlfaFactory' target='_blank' rel='noopener noreferrer'>@AlfaFactory</a></span>
              </h1>
            )}

            {step === 2 && state.current_count === 1 && state.close_test_text === '' && (
              <>
                <NameField state={state} dispatch={dispatch} />
                <PhoneNumberField state={state} dispatch={dispatch} />
                <EmailField state={state} dispatch={dispatch} />
                <DateOfBirthField state={state} dispatch={dispatch} />
                <CitizenshipField state={state} dispatch={dispatch} />
                <MoscowRegion state={state} dispatch={dispatch} />
                {state.moscow_region !== '' && state.moscow_region !== state.moscow_region_choices[0] && (
                  <LivingCityField state={state} dispatch={dispatch} />
                )}
                {/* <Captcha
                  captcha={captcha}
                  setCaptcha={setCaptcha}
                  captchaValue={captchaValue}
                  setCaptchaValue={setCaptchaValue}
                  isError={isError}
                  setError={setError}
                  isLoading={isLoading}
                  setLoading={setLoading}
                  errorCounter={errorCounter}
                  setErrorCounter={setErrorCounter}
                  validation={state.validation}
                /> */}
                <p style={{ paddingTop: '25px', marginBottom: '-30px' }}>Заполняя эту форму я подтверждаю, что мне есть 18 лет.</p>
                <PDAField state={state} dispatch={dispatch} />
                {/* {history.location.search !== '?utm_source=tgbot' &&
                  <div className='telegra'>
                    <p>или</p>
                    <p>зарегистрироваться через телеграмм</p>
                    <a href='tg://resolve?domain=alfabattle2_bot&start=ml1' target='_blank' rel="noreferrer" onClick={() => {
                      if (process.env.NODE_ENV === 'production') {
                        ym('reachGoal', 'click_telegram');
                      }
                    }}>
                      <img src='https://static.tildacdn.com/tild6532-3465-4465-b837-623766366435/tel_logo.svg' alt='telegra'/>
                    </a>
                  </div>
                } */}
              </>
            )}
            {state.close_test_text !== '' && (
              <>
                <h1 className='test__question-title' style={{ textAlign: 'left' }}>
                {(() => {
                    if (state.close_test_text.indexOf('https://t.me/AlfaFactory') !== -1) {
                      const arr = state.close_test_text.split('https://t.me/AlfaFactory')

                      return (
                        <>
                          {arr[0].split(/\n/g).map((i, j) => (
                            <>
                              {i}
                              {j !== arr[0].split(/\n/g).length - 1 && (
                                <br />
                              )}
                            </>
                          ))} <span> <a href='https://t.me/AlfaFactory' target='_blank' rel='noopener noreferrer'>@AlfaFactory</a></span>
                          {arr[1].split(/\n/g).map(i => (
                            <>
                              {i}
                              <br />
                            </>
                          ))} 
                        </>
                      )
                    } else {
                      return state.close_test_text.split(/\n/g).map(i => (
                        <>
                          {i}
                          <br />
                        </>
                      ))
                    }
                  })()}
                </h1>
                {state.link_test !== '' && (
                  <div className='test__link-button-wrapper'>
                    <a href={state.link_test} className='test__link-button' target='_blank' rel="noopener noreferrer">Далее</a>
                  </div>
                )}
              </>
            )}

          </div>
        </div>
        {state.close_test_text === '' && (
        <div className='test__btn-block'>
          <>
            {/* <button
              style={{ opacity: '0' }}
              className={`test__btn-prev ${state.current_count === 1 ? 'test__btn-prev_disabled' : ''}`}
              type='button'
              disabled={state.current_count === 1}
              onClick={() => handlePrev()}
            >
            </button> */}
            <div className='test__btn-block' style={{ justifyContent: 'flex-end', padding: 0 }}>
              <div className='test__link-button-wrapper' style={{ marginTop: '20px' }}>
                <button onClick={() => handleNext()} className='test__link-button' disabled={state.is_pending} style={{ opacity: `${state.is_pending ? 0.7 : 1}` }}>Далее</button>
              </div>
            </div>
            {/* <button
              className={`test__btn-next ${state.is_pending ? 'test__btn-next_disabled' : ''}`}
              type='submit'
              disabled={state.is_pending}
              onClick={() => handleNext()}
            >
            </button> */}
          </>
        </div>
        )}
        {/* {state.close_test_text !== '' && (
          <Footer />
        )} */}
      </div>
    </div>
  );
};

export default Registration;
