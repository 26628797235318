import React from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import TextInput from '../../../components/TextInput';
import { phoneValidation } from '../../../shared/functions/validations';

const PhoneNumberField = ({ state, dispatch }) => {
  return (
    <>
      <h1 className='test__question-title'>Номер мобильного телефона*</h1>
      <div>
        {/* <TextInput
          value={state.phone}
          dispatch={dispatch}
          dispatchType='SET_PHONE'
          placeholder='87771234567'
          validation={state.validation}
          error={state.phone.trim().length === 0}
          errorText={'Пожалуйста, введите номер телефона'}
          type='phone'
        /> */}
        <PhoneInput
          onlyCountries={['ru']}
          disableCountryCode={false}
          value={state.phone}
          placeholder='+7 (777) 777-77-77'
          disableDropdown
          disableSearchIcon={false}
          showDropdown={false}
          onChange={phone => {
            if (/\d+/.test(phone) && phone.length === 1 && phone[0] !== '7' && phone[0] !== '8') {
              dispatch({ type: 'SET_PHONE', payload: `7${phone}` });
            } else if (String(phone).length < 12) {
              if (phone.length === 1 && phone[0] === '8') {
                dispatch({ type: 'SET_PHONE', payload: `7` });
              } else {
                dispatch({ type: 'SET_PHONE', payload: phone });
              }
            }

            return;
          }}
          masks={'+. (...) ...-..-..'}
          defaultMask={'+. (...) ...-..-..'}
          containerClass='text-input'
        />
        {state.validation && !phoneValidation(state.phone) && (
          <span style={{ position: 'relative', top: 5, color: '#ef3124', fontSize: '12px' }}>Пожалуйста, введите номер телефона</span>
        )}
      </div>
    </>
  );
};

export default PhoneNumberField;
