import React from 'react';
import PropTypes from 'prop-types';

import './RadioGroup.scss';

const RadioGroup = ({ items, value, dispatch, dispatchType }) => {
  const handleChecked = e => {
    dispatch({
      type: dispatchType,
      payload: e,
    });
  };

  return (
    <div className='radio-group-wrapper'>
      {items.map((answer, index) => (
        <label key={`radio-group-${index}`} htmlFor={`radio-group-${index}`} className='radio-group__label'>
          <input
            type='radio'
            id={`radio-group-${index}`}
            name='radio question'
            value={answer}
            checked={items[index] === value}
            onChange={e => handleChecked(e.target.value)}
            className={`radio-group__radio ${items[index] === value ? 'checked' : ''}`}
          />
          <div className='radio-group__radio-indicator' />
          {answer}
        </label>
      ))}
    </div>
  );
};

RadioGroup.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
  value: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  dispatchType: PropTypes.string.isRequired,
};

export default RadioGroup;
