import { citizenshipTextValidation, citizenshipValidation, dobValidation, emailValidation, firstNameValidation, lastNameValidation, livingCityValidation, middleNameValidation, phoneValidation } from "./validations";

export const registrationValidation = ({
  lastName,
  firstName,
  middleName,
  email,
  dob,
  phone,
  livingCity,
  pdAgreement,
  citizenship,
  moscowRegion,
  moscowRegionChoices,
  citizenshipText,
}) => {
  const isMoskow = moscowRegion === moscowRegionChoices[0]

  return (
    lastNameValidation(lastName) &&
    firstNameValidation(firstName) &&
    middleNameValidation(middleName) &&
    emailValidation(email) &&
    dobValidation(dob) &&
    (isMoskow || (moscowRegion !== moscowRegionChoices[0] && livingCityValidation(livingCity))) &&
    phoneValidation(phone) &&
    citizenshipValidation(citizenship) &&
    (citizenship === '2. Другое' ? citizenshipTextValidation(citizenshipText) : true) &&
    moscowRegion !== '' &&
    pdAgreement
  );
};
