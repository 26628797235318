import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import './CheckboxGroup.scss';

const CheckboxGroup = ({ items, value, dispatch, dispatchType, exeptions }) => {
  useEffect(() => {
    if (value === '') dispatch({ type: dispatchType, payload: [] });
  }, [dispatch, dispatchType, items.length, value]);

  const handleChecked = val => {
    if (exeptions.includes(val)) {
      let newAnswer = value;

      if (value.includes(val)) {
        newAnswer = [];
      } else {
        newAnswer = [val];
      }

      dispatch({ type: dispatchType, payload: newAnswer });
    } else {
      const newAnswer = value;

      if (value.includes(val)) {
        dispatch({ type: dispatchType, payload: newAnswer.filter(i => i !== val) });
      } else {
        newAnswer.push(val);

        dispatch({ type: dispatchType, payload: newAnswer.filter(i => !exeptions.includes(i)) });
      }
    }
  };

  return (
    <div className='checkbox-group'>
      {items.map((answer, index) => (
        <div key={`radion-group-${answer.id}`} className='pd-agreement'>
          <label className='pd-agreement__label'>
            <input
              type='checkbox'
              name='checkbox answer'
              value={answer}
              checked={value.includes(answer)}
              className='pd-agreement__input'
              onChange={e => handleChecked(e.target.value)}
            />
            <div className={`pd-agreement__checkbox ${value.includes(answer) ? 'checked' : ''}`} />
            {answer.split(/\n/g).map(i => (
              <>
                {i.replace(/\s/g, '\u00A0')}
                <br />
              </>
            ))}
          </label>
        </div>
      ))}
    </div>
  );
};

CheckboxGroup.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
  value: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  dispatchType: PropTypes.string.isRequired,
};

export default CheckboxGroup;
