import React from 'react';
import PropTypes from 'prop-types';

import './RadioGroupWithOther.scss';

const RadioGroupWithOther = ({ items, value, dispatch, dispatchType, otherQ, otherDispatchType, otherAnswer }) => {
  const handleChecked = e => {
    dispatch({ type: dispatchType, payload: e });
  };

  return (
    <div className='radio-group-wrapper'>
      {items.map((answer, index) => (
        <>
          <label key={`radio-group-${index}`} htmlFor={`radio-group-${index}`} className='radio-group__label'>
            <input
              type='radio'
              id={`radio-group-${index}`}
              name='radio question'
              value={answer}
              checked={items[index] === value}
              onChange={e => {
                handleChecked(e.target.value)

                if (e.target.value !== otherQ) {
                  dispatch({ type: otherDispatchType, payload: '' })
                }
              }}
              className={`radio-group__radio ${items[index] === value ? 'checked' : ''}`}
            />
            <div className='radio-group__radio-indicator' />
            {answer}
          </label>
          {answer === otherQ && items[index] === value && (
            <div className='text-input'>
              <input
                type='text'
                value={otherAnswer}
                onChange={e => dispatch({ type: otherDispatchType, payload: e.target.value })}
                maxLength='50'
                placeholder={`${answer}`}
              />
            </div>
          )}
        </>
      ))}
    </div>
  );
};

RadioGroupWithOther.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
  value: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  dispatchType: PropTypes.string.isRequired,
  otherQ: PropTypes
};

export default RadioGroupWithOther;
