import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';
import { YMInitializer } from 'react-yandex-metrika';
import ReactPixel from 'react-facebook-pixel';

import Registration from './pages/registration/Registration';
import RegistrationSmall from './pages/registrationSmall/Registration';
import PhonePage from './pages/phone-page';
import TestQuestions from './pages/test-questions/TestQuestions';
import ConfirmPage from './pages/confirm-page/ConfirmPage';

import './App.scss';

const App = () => {
  // useEffect(() => {
  //   ReactPixel.init(1917004228430836);
  // }, []);

  return (
    <>
      {/* {process.env.NODE_ENV === 'production' && (
        <YMInitializer accounts={[66666666]} options={{
          webvisor: true,
          clickmap: true,
          trackLinks: true,
          accurateTrackBounce: true,
        }} />
      )} */}
      <Route exact path='/' component={Registration} />
      {/* <Route exact path='/registration' component={RegistrationSmall} /> */}
      {/* <Route exact path='/Alfabank/:id' component={Registration} />
      <Route exact path='/X5/:id' component={Registration} />
      <Route exact path='/Beeline/:id' component={Registration} />
      <Route exact path='/Alfabank/' component={Registration} />
      <Route exact path='/X5/' component={Registration} />
      <Route exact path='/Beeline/' component={Registration} />
      <Route exact path='/AlfaStrakhovanie/:id' component={Registration} />
      <Route exact path='/AlfaStrakhovanie/' component={Registration} /> */}

      <Route exact path='/test/:id' component={TestQuestions} />
      <Route exact path='/phone/:id' component={PhonePage} />

      <Route exact path='/confirmation/:id' component={ConfirmPage} />
    </>
  );
};

export default App;
