import React from 'react';
import TextInput from '../../../components/TextInput';
import { testEmail } from '../../../shared/functions/validations';

const EmailField = ({ state, dispatch }) => {
  return (
    <>
      <h1 className='test__question-title'>Адрес электронной почты*</h1>
        <TextInput
          value={state.email}
          dispatch={dispatch}
          dispatchType='SET_EMAIL'
          placeholder='example@email.com'
          validation={state.validation}
          error={!testEmail(state.email)}
          errorText={'Пожалуйста, введите email в формате example@email.com'}
        />
    </>
  );
};

export default EmailField;
