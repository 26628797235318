const requestRegistrationData = state => {
  return {
    last_name: state.last_name.trim(),
    first_name: state.first_name.trim(),
    middle_name: state.middle_name === '' ? '-' : state.middle_name.trim(),
    dob: state.dob.trim(),
    is_russian_citizenship: state.is_russian_citizenship === '1. Российская Федерация',
    ...(state.is_russian_citizenship !== '1. Российская Федерация' ? { citizenship: state.citizenship } : {}),
    moscow_region: state.moscow_region,
    email: state.email.trim(),
    phone: state.phone.trim(),
    living_city: state.living_city.trim() === '' ? 'Москва' : state.living_city.trim(),
    pd_agreement: state.pd_agreement,
  };
};

export default requestRegistrationData;
